import React from 'react'
import { Data as MessageProps } from '../Chat.typings'
import stylesBase from './Message.module.scss'

const Message: React.FC<MessageProps> = ({ name, text, action }) => {
    const formatMessage = (name: string, message: string, action: string) => {
        switch (action) {
            case 'all':
                return (
                    <div>
                        <span className={stylesBase.messageNormalName}>
                            {name}:
                        </span>
                        <span className={stylesBase.messageNormalText}>
                            {message}
                        </span>
                    </div>
                )
            case 'me':
                return (
                    <div className={stylesBase.messageMe}>
                        {name}:{' '}
                        {message.charAt(0).toLowerCase() + message.slice(1)}
                    </div>
                )
            case 'do':
                return (
                    <div className={stylesBase.messageDo}>
                        {name}:{' '}
                        {message.charAt(0).toUpperCase() + message.slice(1) + '.'}
                    </div>
                )
            case 'todo': {
                const newMessageTodo = message.split('*')
                return (
                    <div>
                        <span className={stylesBase.messageTodoStart}>
                            {newMessageTodo[0].charAt(0).toUpperCase() +
                                newMessageTodo[0].slice(1) +
                                ' - '}
                        </span>
                        <span className={stylesBase.messageTodoEnd}>
                            {' ' + 'сказал' + ' ' + name},{' '}
                            {newMessageTodo[1].charAt(0).toLowerCase() +
                                newMessageTodo[1].slice(1)}
                        </span>
                    </div>
                )
            }
            case 'try': {
                const newMessageTry = message.split('(')
                return (
                    <div>
                        <span className={stylesBase.messageMe}>
                            {name + ' '}
                        </span>
                        <span className={stylesBase.messageMe}>
                            {newMessageTry[0]}
                        </span>
                        <span>
                            (
                            <span
                                className={
                                    newMessageTry[1].substring(
                                        0,
                                        newMessageTry[1].length - 1
                                    ) === 'удачно'
                                        ? stylesBase.messageTryGood
                                        : stylesBase.messageTryBad
                                }
                            >
                                {newMessageTry[1].substring(
                                    0,
                                    newMessageTry[1].length - 1
                                )}
                            </span>
                            )
                        </span>
                    </div>
                )
            }
            case 'b':
                return (
                    <div>
                        {name}: {'((' + message + '))'}
                    </div>
                )
            case 's':
                return (
                    <div className={stylesBase.messageS}>
                        {name + ' кричит '}: {message + '!'} 
                    </div>
                )
            case 'w':
                return (
                    <div className={stylesBase.messageW}>
                        {name + ' шепчет '}: {message}
                    </div>
                )
        }
    }
    return <span>{formatMessage(name, text, action)}</span>
}

export default Message
