interface HotKey{
    key:string
    title:string
}

interface HotKeys{
    [key:string]:HotKey[]
}

export const hotkeys:HotKeys = {
    inVehicle: [
        {
            key: 'F1',
            title: 'Телефон'
        },
        {
            key: 'F2',
            title: 'Инвентарь'
        },
        {
            key: 'F3',
            title: 'Меню игрока'
        },
        {
            key: 'F4',
            title: 'Помощь'
        },
        {
            key: 'F5',
            title: 'Чат'
        },
        {
            key: 'F6',
            title: 'Пристегнуть ремень'
        },
        {
            key: 'F7',
            title: 'Завести двигатель'
        },
        {
            key: 'F8',
            title: 'Замок транспорта'
        },
        {
            key: 'F9',
            title: 'Взаимодействие с транспортом'
        },
        {
            key: 'F10',
            title: 'Окно транспорта'
        },
    ],
    nearTheVehicle: [
        {
            key: 'F1',
            title: 'Телефон'
        },
        {
            key: 'F2',
            title: 'Инвентарь'
        },
        {
            key: 'F3',
            title: 'Меню игрока'
        },
        {
            key: 'F4',
            title: 'Помощь'
        },
        {
            key: 'F5',
            title: 'Чат'
        },
        {
            key: 'F6',
            title: 'Замок транспорта'
        },
        {
            key: 'F7',
            title: 'Сесть на пассажирское сиденье'
        },
        {
            key: 'F8',
            title: 'Взаимодействие с транспортом'
        },
    ],
    farFromVehicle: [
        {
            key:'F1',
            title: 'Телефон'
        },
        {
            key:'F2',
            title: 'Взаимодействие с игроком'
        },
        {
            key:'F3',
            title: 'Инвентарь'
        },
        {
            key:'F4',
            title: 'Меню игрока'
        },
        {
            key:'F5',
            title: 'Справить нужду'
        },
        {
            key:'F6',
            title: 'Выбор анимации'
        },
        {
            key:'F7',
            title: 'Помощь'
        },
        {
            key:'F8',
            title: 'Курсор'
        },
        {
            key:'F9',
            title: 'Чат'
        },
    ]
}