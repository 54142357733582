import React, { SVGProps, useEffect, useState } from 'react'
import styles from './InteractionWheel.module.scss'
import { ItemsWheel } from 'entities/ui/InteractionWheel/components/ItemsWheel/ItemsWheel'
import classNames from 'classnames'
import { characterInteractions } from 'entities/ui/InteractionWheel/InteractionWheel.config'
import {InteractionsParams} from "entities/ui/InteractionWheel/model/useInteractionWheelData";

interface Item {
    Icon: (props: SVGProps<SVGSVGElement>) => JSX.Element
    key: string | null
    title: string
    items?: Item[]
}

interface Props {
    onClick: (type: string) => void
    type: string
    params: InteractionsParams
}

export interface HoverItem extends Item {
    index: number
}

export const InteractionWheel: React.FC<Props> = ({
    onClick,
    type = 'character',
    params
}) => {
    const items = characterInteractions[type](params)
    const count = items.length
    const [hoverItem, setHoverItem] = useState<HoverItem | null>(null)
    const [hoverSubItem, setHoverSubItem] = useState<HoverItem | null>(null)

    const handleMouseOver = (index: number) => {
        setHoverItem({
            ...items[index],
            index,
        })
    }

    const handleMouseOverSubItem = (index: number) => {
        const item = hoverItem?.items?.[index]

        if (item) {
            setHoverSubItem({
                ...item,
                index,
            })
        }
    }

    const outerSectors = 16
    const outerAngle = 360 / 16
    const innerAngle = 360 / count
    const rotateOuterDeg =
        hoverItem?.index && hoverItem.items
            ? (hoverItem.index + 0.5) * innerAngle -
              (outerAngle * hoverItem.items?.length) / 2
            : 0

    useEffect(() => {
        setHoverSubItem(null)
    }, [hoverItem])

    return (
        <div className={classNames(styles.wrapperOuter, styles.bigCircle)}>
            {hoverItem?.items && (
                <ItemsWheel
                    items={hoverItem?.items}
                    hoverItem={hoverSubItem}
                    onClick={onClick}
                    onMouseOver={handleMouseOverSubItem}
                    sectorWidth={68}
                    length={outerSectors}
                    rotate={rotateOuterDeg}
                    containerSize={800}
                    iconSize={42}
                />
            )}
            <div className={styles.wrapperInner}>
                <ItemsWheel
                    items={items}
                    hoverItem={hoverItem}
                    onClick={onClick}
                    onMouseOver={handleMouseOver}
                    hasCircle
                    containerSize={565}
                />
                <div className={styles.title}>
                    {hoverSubItem?.title || hoverItem?.title}
                </div>
            </div>
        </div>
    )
}
