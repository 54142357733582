import { previewStore } from 'widgets/ui/Store/__mocks__/preview';

export const EVENTS = [
    {
        title: "Подавление_близко",
        event: "supression:show",
        body: {
            data: {
                level: 1
            }
        }
    },
    {
        title: "Подавление_средне",
        event: "supression:show",
        body: {
            data: {
                level: 2
            }
        }
    },
    {
        title: "Подавление_далеко",
        event: "supression:show",
        body: {
            data: {
                level: 3
            }
        }
    },
    {
        title: "Ранение",
        event: "supression:show",
        body: {
            data: {
                level: 4
            }
        }
    },
    {
        title: "Инфо в худе",
        event: "hud:info",
        body: {
            data: {
                pos: {
                    street: "Улица",
                    zone: "Зона"
                },
                money: "1000",
                date: new Date()
            }
        }
    },
    {
        title: "ATM",
        event: "route",
        body: {
            data: {
                path: "/atm"
            }
        }
    },
    {
        title: "Store",
        event: "store:show",
        body: {
            data: {
                show: true,
                params: {
                    ...previewStore
                }
            }
        }
    },
    {
        title: "TattooShop",
        event: "route",
        body: {
            data: {
                path: "/tattooShop"
            }
        }
    },
    {
        title: "Колесо взаимодействия (персонаж)",
        event: 'interactions:showModal',
        body: {
            data: {
                show: true,
                type: "character",
                params: {
                    name: "name",
                    hasCuff: true,
                    hasRope: true,
                    hasBag: true
                }
            }
        }
    },
    {
        title: "Колесо взаимодействия (авто внутри)",
        event: 'interactions:showModal',
        body: {
            data: {
                show: true,
                type: "inVehicle",
                params: {
                    passenger: [{ id: 1, name: 'vasya' }, { id: 2, name: 'dima' }]
                }
            }
        }
    },
    {
        title: "Колесо взаимодействия (авто снаружи)",
        event: 'interactions:showModal',
        body: {
            data: {
                show: true,
                type: "outVehicle"
            }
        }
    },
    {
        title: "Экран смерти",
        event: 'route',
        body: {
            data: {
                path: '/deathScreen',
                params: {
                    name: "John Doe",
                    dateOfBirth: 1978,
                }
            }
        }
    },
    {
        title: "Экран критического состояния",
        event: 'route',
        body: {
            data: {
                path: '/criticalStateScreen',
            }
        }
    },
    {
        title: "Чат",
        event: 'chat:getMessage',
        body: {
            data: {
                id: 1,
                name: 'vasya',
                text: 'hello everybody',
                channel: 'all',
                action: 'all'
            }
        }
    },
    {
        title: 'Хинты',
        event: 'hud:hotkeys',
        body: {
            data: {
                show: true,
                type: 'farFromVehicle',
                activeKeys: ['F1', 'F4']
            }
        }
    },
    {
        title: 'Тикеты',
        event: 'hud:tickets',
        body: {
            data: {
                show: true,
                params: {
                    reports: 3,
                    moderation: 5,
                    experts: 4
                }

            }
        }
    },
    {
        title: "Лобби персонажа",
        event: 'route',
        body: {
            data: {
                path: '/PlayerMenu',
            }
        }
    },
    {
        title: "Регистрация",
        event: "auth:sign-up",
        body: {
            data: {
                username: "test",
                email: "test@email.ru",
                password: "test"
            }
        }
    },
    {
        title: "Авторизация",
        event: "auth:login",
        body: {
            data: {
                username: "test",
                email: "test@email.ru"
            }
        }
    },
    {
        title: "PaymentSystem",
        event: "route",
        body: {
            data: {
                path: "/paymentSystem"
            }
        }
    },
]

export const RPC_METHODS = [{
    label: "trigger",
    value: "trigger"
},
    {
        label: "triggerServer",
        value: "triggerServer"
    },
    {
        label: "call",
        value: "call"
    },
    {
        label: "callServer",
        value: "callServer"
    },
    {
        label: "xrpc:emitClient",
        value: "xrpc:emitClient"
    },
    {
        label: "xrpc:emitServer",
        value: "xrpc:emitServer"
    },
]