import React from 'react'
import { Tag, Channel, Data } from './Chat.typings'
import stylesBase from './Chat.module.scss'
import send from './assets/sendImg.svg'
import { useState } from 'react'
import { useEffect } from 'react'
import Message from './components/Message'
import rpc from 'altv-rpc'
import { RpcResponse } from 'shared/typings/common'


export const Chat = () => {

    const [tags] = useState<Tag[]>(
        [
            {id: 1, text: 'Tag1'},
            {id: 2, text: 'Tag2'}
        ]
    )
    const [channels] = useState<Channel[]>(
        [
            {id: 1, text: 'channel1', color: 'red'},
            {id: 2, text: 'channel2', color: 'green'},
            {id: 3, text: 'All', color: 'blue'}
        ]
    )
    const [messages, setMessages] = useState<Data[]>([
        {id: 2, name: 'vasya', text: 'vjdkjfdkjf', channel: 'channel1', action: 'all'},
        {id: 3, name: 'vasya', text: 'vjdkjfdkjf', channel: 'channel2', action: 'me'},
        {id: 4, name: 'vasya', text: 'vjdkjfdkjf', channel: 'channel3', action: 'do'},
        {id: 5, name: 'vasya', text: 'vjdkj*fdkjf', channel: 'channel1', action: 'todo'},
        {id: 6, name: 'vasya', text: 'vjdkj(fdkjf', channel: 'channel1', action: 'try'},
        {id: 7, name: 'vasya', text: 'vjdkj(fdkjf', channel: 'channel1', action: 'b'},
        {id: 8, name: 'vasya', text: 'vjdkj(fdkjf', channel: 'channel1', action: 's'},
        {id: 9, name: 'vasya', text: 'vjdkj(fdkjf', channel: 'channel1', action: 'w'},
    ])

    const handleGetMessage =  ({data}: RpcResponse<Data>) => {
        setMessages(prev => [...prev, data])
    }

    const event = 'chat:getMessage'

    useEffect(() => {
        rpc.on(event, handleGetMessage)

        return () => {
            rpc.off(event, handleGetMessage)
        }
    })

    const onSend = ({message, channel, action}:{message:string, channel:string, action:string}) => {
        rpc.callServer('chat:send', {data: {message, channel, action}})
    }

    const onCommand = ({message}:{message:string}) => {
        rpc.callServer('chat:command', {data: {message}})
    }

    const [action, setAction] = useState('all')
    const handleAction = (event: any) => {
        setAction(tags[event].text)
    }

    const [value, setValue] = useState('')
    const handleSubmit = (event:React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (value != '') {
            if (value[0] != '/') {
                onSend({
                    message: value,
                    channel: channels[channelIndex].text,
                    action,
                })
                setValue('')
                setAction('all')
            } else {
                onCommand({
                    message: value,
                })
                setValue('')
                setAction('all')
            }
        }
    }

    const [channelIndex, setChannelIndex] = useState(0)

    useEffect(() => {
        const callback = (e: any) => {
            if (e.keyCode === 9) {
                e.preventDefault()
                setChannelIndex(
                    channelIndex + 1 >= channels.length ? 0 : channelIndex + 1
                )
            }
        }

        document.addEventListener('keydown', callback)

        return () => {
            document.removeEventListener('keydown', callback)
        }
    }, [channelIndex])

    return (
        <div className={stylesBase.chat}>
            <div className={stylesBase.chatListOverflow}>
                <div className={stylesBase.chatList}>
                    {messages.map((item, index) => (
                        <Message {...item} key={index} />
                    ))}
                </div>
            </div>
            <div className={stylesBase.chatInput}>
                <form onSubmit={handleSubmit}>
                    <div className={stylesBase.chatInfo}>
                        {channels[channelIndex].text != 'all' && (
                            <div className={stylesBase.chatStatus}>
                                {channels[channelIndex].text}
                            </div>
                        )}
                        <input
                            type="text"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                        />
                    </div>
                    <button type="submit">
                        <img src={send} alt="send" />
                    </button>
                </form>
            </div>
            <div className={stylesBase.chatTagList}>
                {tags.map((item, index) => (
                    <div
                        className={
                            stylesBase.chatTagBox +
                            ' ' +
                            (action == item.text
                                ? stylesBase.chatTabBoxActive
                                : '')
                        }
                        key={index}
                        onClick={() => handleAction(index)}
                    >
                        {item.text?.toUpperCase()}
                    </div>
                ))}
            </div>
        </div>
    )
}
