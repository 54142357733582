import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import type { SignupProps, LoginProps } from './types';
import { rpc } from "altv-xrpc-webview"

export const authApi = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({
    register: builder.mutation<SignupProps, SignupProps>({
      async queryFn(data) {
        try {
          const res = await rpc.emitServerWithTimeout("auth:sign-up", 2000, data);
          return { data:res };
        } catch (error) {
          return { error: error };
        }
      },
    }),
    login: builder.mutation<LoginProps, LoginProps>({
      async queryFn(data) {
        try {
          const res = await rpc.emitServerWithTimeout("auth:login", 2000, data);
          return { data:res };
        } catch (error) {
          return { error: error };
        }
      },
    }),
  }),
});

export const { useRegisterMutation, useLoginMutation } = authApi;
