import React from 'react'
import styles from './HudInfo.module.scss'
import { HudInfoProps } from './HudInfo.typings'
import moment from 'moment'

export const HudInfo = ({
    date,
    pos: { street, zone },
    money,
}: HudInfoProps) => {
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.title}>
                    {moment(date).format('hh:mm A')}
                </div>
                <div className={styles.subtitle}>
                    {moment(date).format('MM.DD.YYYY')}
                </div>
            </div>
            <div className={styles.line}></div>
            <div>
                <div className={styles.title}>{zone}</div>
                <div className={styles.subtitle}>{street}</div>
            </div>
            <div className={styles.line}></div>
            <div className={styles.title}>$ {money}</div>
        </div>
    )
}
