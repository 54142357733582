import React from 'react'
import styles from 'entities/ui/InteractionWheel/components/ItemsWheel/ItemsWheel.module.scss'
import { getWheelCoords } from '../../utils/getWheelCoords'
import { Item } from '../../InteractionWheel.typings'
import { getSectors } from 'entities/ui/InteractionWheel/utils/getSectors'
import classNames from 'classnames'
import { HoverItem } from 'entities/ui/InteractionWheel/InteractionWheel'
import { pxToRem } from 'shared/utils/pxToRem'

interface ItemsWheelProps {
    items: Item[]
    hoverItem: HoverItem | null
    onClick: (key: string) => void
    onMouseOver: (i: number) => void
    hasCircle?: boolean
    length?: number
    sectorWidth?: number
    rotate?: number
    containerSize: number
    iconSize?: number
}

const diameter = 565
const offsetAngle = 2

export const ItemsWheel: React.FC<ItemsWheelProps> = ({
    items,
    hoverItem,
    onClick,
    onMouseOver,
    hasCircle = false,
    length = items.length,
    sectorWidth = 120,
    rotate,
    containerSize,
    iconSize = 45,
}) => {
    const count = items.length

    const pathsContainerSize = `${pxToRem(containerSize)}rem`
    const blocksContainerSize = pxToRem(
        containerSize * 0.97 - sectorWidth / 2 - iconSize
    )

    return (
        <div
            style={{
                height: pathsContainerSize,
                width: pathsContainerSize,
            }}
            className={styles.wrapper}
        >
            <svg
                className={styles.paths}
                viewBox={`0 0 ${diameter} ${diameter}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: `rotate(${rotate}deg)` }}
            >
                {getSectors(
                    length,
                    offsetAngle,
                    diameter,
                    sectorWidth,
                    count
                ).map((path, i) => {
                    return (
                        <path
                            key={`${path}${i}`}
                            onClick={() => {
                                const item = items[i].key ?? String(items[i].id)

                                if(item){
                                    onClick(item)
                                }
                            }}
                            className={classNames(
                                styles.path,
                                i === hoverItem?.index && styles.pathActive
                            )}
                            strokeWidth={sectorWidth}
                            d={path}
                            onMouseOver={() => onMouseOver(i)}
                        />
                    )
                })}
                {hasCircle && (
                    <circle
                        className={styles.circle}
                        cx={diameter / 2}
                        cy={diameter / 2}
                        r={(diameter - sectorWidth * 2) / 2 - offsetAngle * 4}
                    />
                )}
            </svg>
            <div
                className={styles.blocksWrapper}
                style={{
                    width: `${blocksContainerSize}rem`,
                    height: `${blocksContainerSize}rem`,
                }}
            >
                <div className={styles.container}>
                    {getWheelCoords({
                        totalCount: length,
                        radius: blocksContainerSize / 2,
                        count,
                        bias: -pxToRem(iconSize) / 2,
                        rotate,
                    }).map(({ x, y }, i) => {
                        const item = items[i]
                        const Icon = item.Icon
                        const size = `${pxToRem(iconSize)}rem`

                        return (
                            <>
                            {Icon ? <Icon
                                key={`${x}${y}`}
                                width={size}
                                height={size}
                                style={{
                                    position: 'absolute',
                                    left: `${x}rem`,
                                    top: `${y}rem`,
                                }}
                                color={
                                    hoverItem?.index === i
                                        ? '#252627'
                                        : '#A0A0A0'
                                }
                            />
                            :
                            <div
                                key={`${x}${y}`}
                                style={{
                                    width:size,
                                    height:size,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontSize:`${pxToRem(16)}rem`,
                                    textAlign: 'center',
                                    fontFamily:'roboto',
                                    position: 'absolute',
                                    left: `${x}rem`,
                                    top: `${y}rem`,
                                    color:
                                        hoverItem?.index === i
                                            ? '#252627'
                                            : '#A0A0A0'
                                }}
                            >
                                {item.name}<br/>id:{item.id}
                            </div>}
                            </>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
