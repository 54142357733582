import React from 'react'
import styles from './HudHeader.module.scss'
import onlineSign from './assets/Vectoronline.png'
import pictureImg from './assets/picture.png'

export interface HudHeaderProps {
    onlineText: string
    id: string
}

export const HudHeader = (props: HudHeaderProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <div className={styles.online}>
                    <img className={styles.sign} src={onlineSign} />
                    <span className={styles.text}>{props.onlineText}</span>
                </div>
                <div className={styles.secondTitle}>
                    <span className={styles.idText}>ID</span>
                    <span className={styles.text}>{props.id}</span>
                </div>
            </div>
            <div className={styles.picture}>
                <img className={styles.pictureImg} src={pictureImg} />
            </div>
        </div>
    )
}
