import { configureStore } from '@reduxjs/toolkit';
//import yourReducer from './redux-toolkit/yourSlice'; // Импортируйте свой срез (slice)
import { setupListeners } from '@reduxjs/toolkit/query'
import { authApi } from './api/authApi'

const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    // yourSlice: yourReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
setupListeners(store.dispatch)