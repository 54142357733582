import { useEffect, useState } from 'react'
import { RpcResponse } from 'shared/typings/common'
import rpc from 'altv-rpc'
import {initialInteractionsParams} from "entities/ui/InteractionWheel/InteractionWheel.config";

export interface InteractionsParams {
    name: string
    hasCuff: boolean
    hasRope: boolean
    hasBag: boolean
}

interface InteractionsModalResponse {
    show: boolean
    type?: string
    params?: InteractionsParams
}

export interface Passenger {
    id: number
    name: string
}

export const useInteractionWheelData = () => {
    const [show, setShow] = useState(false)
    const [type, setType] = useState('character')
    const [params, setParams] = useState(initialInteractionsParams)

    const callback = ({
        data: { show, type, params },
    }: RpcResponse<InteractionsModalResponse>) => {
        setShow(show)
        if(type){
            setType(type)
        }
        if(params) {
            setParams(params)
        }
    }

    const event = 'interactions:showModal'

    useEffect(() => {
        rpc.on(event, callback)

        return () => {
            rpc.off(event, callback)
        }
    })

    return { show, type, params }
}
