import React, { useState, useEffect } from 'react'
import styles from './HudHints.module.scss'
import rpc from 'altv-rpc'
import { hotkeys } from './HudHints.config'
import cn from 'classnames'

interface Item {
    key:string
    title:string
}

const HudHints = () => {

    const [type, setType] = useState<null | string>(null)
    const [activeKeys, setActiveKeys] = useState<null | string[]>(null)

    const callback = ({ data }: any) => {
        setType(data.type)
        setActiveKeys(data.activeKeys)
    }

    const event = 'hud:hotkeys'

    useEffect(() => {
        rpc.on(event, callback)
        return () => {
            rpc.off(event, callback)
        }
    })

    return (
        <div className={styles.hints}>
            {
                type && Array.isArray(hotkeys[type]) && hotkeys[type].map((item:Item) => {
                    return (
                        <div
                            key={item.key}
                            className={styles.wrapper}
                        >
                            <div className={cn(styles.key, activeKeys && activeKeys.includes(item.key, 0) && styles.activeKey)}>{item.key}</div>
                            <div className={styles.title}>{item.title}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HudHints